







































import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { HbTimeSlot, HbTimeTable } from '../../types/hybrid'

@Component
export default class extends Vue {
  private dialog = false
  private onGenerate = false
  private onFetch = false
  private timetable: Partial<HbTimeTable>[] = []
  private headers = [
    { text: 'ID', value: 'id' },
    { text: 'Date', value: 'dateTime' },
    { text: 'Capacity', value: 'capacity' },
    { text: 'Slot ID', value: 'timeSlotId' },
    { text: 'Active', value: 'isActive' },
  ]

  mounted() {
    this.fetchTimeTable()
  }

  private async fetchTimeTable() {
    this.onFetch = true
    const { data } = await this.$apollo.query<{
      hbTimeTables: Partial<HbTimeTable>[]
    }>({
      query: gql`
        query FetchHbTimeTable {
          hbTimeTables {
            id
            dateTime
            capacity
            isActive
            timeSlotId
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    if (data) {
      this.timetable = data.hbTimeTables
    }
  }

  private async generate() {
    this.onGenerate = true
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 14)
    endDate.setHours(0, 0, 0, 0)
    const { data } = await this.$apollo.query<{
      hbTimeSlots: Partial<HbTimeSlot>[]
    }>({
      query: gql`
        query GetTimeSlotId {
          hbTimeSlots {
            id
            isActive
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    if (data) {
      const slots = data.hbTimeSlots.filter(e => e.isActive)
      while (date.getTime() <= endDate.getTime()) {
        for (const i of slots) {
          const dateStr = date.toISOString()
          const index = this.timetable.findIndex(
            el => el.dateTime?.slice(0, 10) === dateStr.slice(0, 10)
          )
          if (index < 0 && i.id) {
            const res = await this.createTimeTable(date, i.id)
            if (res) {
              this.timetable.push(res)
            }
          }
        }
        date.setDate(date.getDate() + 1)
      }
    }
    this.dialog = false
    this.onGenerate = false
  }

  private async createTimeTable(date: Date, slotId: number) {
    const { data } = await this.$apollo.mutate<{
      createTimeTable: Partial<HbTimeTable>
    }>({
      mutation: gql`
        mutation CreateNewTimeTable($data: CreateHBTimeTableArgs!) {
          createTimeTable(data: $data) {
            id
            dateTime
            capacity
            isActive
            timeSlotId
          }
        }
      `,
      variables: {
        data: {
          date: date.toISOString(),
          hbTimeSlotId: slotId,
        },
      },
    })
    return data?.createTimeTable
  }
}
